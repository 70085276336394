import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Flex, Box, Image, Link } from 'rebass/styled-components';
import { navigate } from '@reach/router';
import shortid from 'shortid';
import theme from './../../../gatsby-plugin-theme-ui';
import { producers_websites } from './producers_websites';

// MEDIA
import abx from './../../../media/svg/abx.svg';
import charnwood from './../../../media/svg/charnwood.svg';
import chesneys from './../../../media/svg/chesneys.svg';
import defro_home from './../../../media/svg/defro_home.svg';
import dimplex from './../../../media/svg/dimplex.svg';
import dovre from './../../../media/svg/dovre.svg';
import eco_pal from './../../../media/svg/eco_pal.svg';
import faber from './../../../media/svg/faber.svg';
import hajduk from './../../../media/svg/hajduk.svg';
import hein from './../../../media/svg/hein.svg';
import infire from './../../../media/svg/infire.svg';
import kafel_kar from './../../../media/svg/kafel_kar.svg';
import kratki from './../../../media/svg/kratki.svg';
import laminox from './../../../media/svg/laminox.svg';
import nordica from './../../../media/svg/nordica.svg';
import nordica_extraflame from './../../../media/svg/nordica_extraflame.svg';
import planika from './../../../media/svg/planika.svg';
import poujoulat from './../../../media/svg/poujoulat.svg';
import romotop from './../../../media/svg/romotop.svg';
import schmid from './../../../media/svg/schmid.svg';
import stuv from './../../../media/svg/stuv.svg';
import unico from './../../../media/svg/unico.svg';
import wentor from './../../../media/svg/wentor.svg';
import spartherm from './../../../media/svg/spartherm.svg';
import laminam from './../../../media/svg/laminam.svg';
import unical from './../../../media/svg/unical.svg';
import mcz from './../../../media/svg/mcz.svg';
import bellfires from './../../../media/svg/bellfires.svg';
import brunner from './../../../media/png/brunner.png';
import kobok from './../../../media/svg/kobok.svg';
import thorma from './../../../media/svg/thorma.svg';
import cadel from './../../../media/svg/cadel.svg';

// STYLES
import {
    offer_details_section_props,
    offer_details_heading_props,
    offer_details_text_props,
    producers_container_props,
    producers_heading_props,
    border_top_props,
    border_bottom_props,
    border_right_props,
    border_left_props,
} from './styles';

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';
import Border from './../../atoms/border';
import Button from './../../atoms/button';
import Modal from './modal';

export default function OfferDetails(props) {

  const { data } = props;

  const { title, description, producers } = data.allSitePage.edges[0].node.context;

  const [showModal, setShowModal] = useState(false);

  const offer_details_producers_display_props = length => ({
      as: 'div',
      sx: {
          width: '100%',
          flexFlow: 'row wrap',
          justifyContent: length <= 2 ? 'center' : 'flex-start',
          alignItems: 'center',
          padding: [0, '0 0.5rem 2rem 0.5rem']
      }
  })

  const offer_details_producers_display_item_props = item => ({
      sx: {
        display: 'flex',
        width: ['100%', 'calc(50% - 1rem)', 'calc(33.3% - 1rem)'],
        minWidth: 150,
        maxWidth: 360,
        height: 200,
        background: theme.colors.lightgrey,
        margin: ['0.5rem auto', '0.5rem'],
        justifyContent : 'center',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
        '> img': {
            width: item === 'schmid' ? 220 : 
                   item === 'charnwood' ? 60 : 
                   item === 'planika' ? 180 : '100%',
            maxWidth: 180,
            maxHeight: '70%'
        }
      }
  })

  const conditional = title !== 'Materiały wykończeniowe' &&
                      title !== 'Materiały instalacyjno-montażowe';

  return (
    <Section {...offer_details_section_props}>
        <Heading {...offer_details_heading_props}>{ title }</Heading>
        <Box padding={['0 1.25rem', '0 1.25rem', 0]}
        data-sal='slide-up'
        data-sal-duration='600'
        data-sal-delay='0'
        data-sal-easing='cubic-bezier(0.165, 0.84, 0.44, 1)'>
            <Text {...offer_details_text_props('2.5rem')}>{ description.paragraph1 }</Text>
            <Text {...offer_details_text_props('5rem')}>{ description.paragraph2 }</Text>
        </Box>
        {
            producers.length === 0 ? 
            <Text margin='0 0 3rem 0' padding={['0 1.25rem', '0 1.25rem', 0]} fontWeight={700}>
                W celu zamówienia materiałów instalacyjnych bądź montażowych prosimy o kontakt.
            </Text> :
                <Box {...producers_container_props}
                data-sal='fade'
                data-sal-duration='600'
                data-sal-delay='400'
                data-sal-easing='cubic-bezier(0.165, 0.84, 0.44, 1)'>
                <Border {...border_top_props} />
                <Border {...border_left_props} />
                <Border {...border_bottom_props} />
                <Border {...border_right_props} />
                <Heading {...producers_heading_props(conditional)}>{
                    conditional ?
                    'Lista producentów' : 'Lista materiałów'
                }</Heading>
                    <Flex {...offer_details_producers_display_props(producers.length)}>
                        {
                            producers.map(item => {
                                const item_name = item.replace('.svg', '');
                                const url = item_name === 'abx' ? abx :
                                            item_name === 'charnwood' ? charnwood :
                                            item_name === 'chesneys' ? chesneys :
                                            item_name === 'defro_home' ? defro_home :
                                            item_name === 'dimplex' ? dimplex :
                                            item_name === 'dovre' ? dovre :
                                            item_name === 'eco_pal' ? eco_pal :
                                            item_name === 'faber' ? faber :
                                            item_name === 'hajduk' ? hajduk :
                                            item_name === 'hein' ? hein :
                                            item_name === 'infire' ? infire :
                                            item_name === 'kafel_kar' ? kafel_kar :
                                            item_name === 'kratki' ? kratki :
                                            item_name === 'laminox' ? laminox :
                                            item_name === 'nordica' ? nordica :
                                            item_name === 'nordica_extraflame' ? nordica_extraflame :
                                            item_name === 'planika' ? planika :
                                            item_name === 'poujoulat' ? poujoulat :
                                            item_name === 'romotop' ? romotop :
                                            item_name === 'schmid' ? schmid :
                                            item_name === 'stuv' ? stuv :
                                            item_name === 'unico' ? unico :
                                            item_name === 'wentor' ? wentor :
                                            item_name === 'spartherm' ||
                                            item_name === 'spartherm_gazowe' ||
                                            item_name === 'spartherm_bio' ? spartherm :
                                            item_name === 'laminam' ? laminam :
                                            item_name === 'unical' ? unical :
                                            item_name === 'mcz' ? mcz : 
                                            item_name === 'bellfires' ? bellfires : 
                                            item_name === 'cadel' ? cadel : 
                                            item_name === 'kobok' ? kobok : 
                                            item_name === 'thorma' ? thorma : 
                                            item_name === 'brunner' ? brunner : null;

                                return (
                                    <Link key={shortid.generate()}
                                    href={producers_websites[`${item_name}`]}
                                    target='_blank' rel='noopener noreferrer'
                                    {...offer_details_producers_display_item_props(item_name)}
                                    onClick={ 
                                        Object.keys(producers_websites).filter(
                                            item => item === item_name
                                        ).length === 0 ? 
                                        () => setShowModal(true) : null }>
                                    {
                                        conditional ? 
                                        <Image src={url} alt={`${item}`} /> :
                                        <Heading as='h2'>{item_name}</Heading>
                                    }
                                    </Link>
                                )
                            })
                        }
                    </Flex>
                </Box>
        }
        <Button fontSize={[18, 20, 20]} margin='2.5rem 0 0 0'
        onClick={() => navigate('/oferta/')}>... Powrót do listy ...</Button>
        <Modal status={showModal} onClick={() => setShowModal(false)}
        title='Skontaktuj się z nami'
        text='Napisz do nas w celu omówienia szczegołów zamówienia:' />
    </Section>
  );
}

// QUERY

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: { path: { eq: $path } }) {
            edges {
                node {
                    context {
                        title,
                        slug,
                        icon,
                        short_description,
                        description {
                            paragraph1,
                            paragraph2
                        },
                        producers,
                    }
                }
            }
        }
    }
`