export const producers_websites = {
    abx: 'http://abxkominki.pl/',
    charnwood: 'http://www.charnwood.pl/',
    chesneys: 'https://chesneys.co.uk/',
    defro_home: 'https://www.defrohome.pl/',
    dimplex: 'http://optiflame.pl/',
    dovre: 'https://dovre.com.pl/',
    eco_pal: 'http://www.eco-pal.pl/',
    faber: 'https://www.faberfires.com/pl-pl/kominki/all-fireplaces',
    hajduk: 'https://hajduk.eu/',
    hein: 'https://www.kafle-hein.pl/',
    infire: 'http://www.infire.pl/pl',
    kafel_kar: 'https://kafel-kar.pl/',
    kratki: 'https://kratki.com/pl/',
    laminox: 'https://www.laminox.com/site_idro/index.php?lang=us',
    nordica: 'https://lanordica-extraflame.pl/',
    nordica_extraflame: 'https://lanordica-extraflame.pl/',
    planika: 'https://www.planikafires.pl/',
    poujoulat: 'https://poujoulat.pl/',
    romotop: 'https://www.romotop.pl/',
    schmid: 'https://schmid-kominki.pl/',
    stuv: 'https://www.stuv.com/en-gb/',
    unico: 'http://www.unico-kominki.com/',
    wentor: 'https://www.wentor.pl/',
    spartherm: 'http://www.spartherm.pl/',
    spartherm_gazowe: 'http://www.spartherm.pl/produkty/wklady-kominkowe?atr%5BatrWkladyKominkoweNaGaz%5D=1',
    spartherm_bio: 'http://www.spartherm.pl/produkty/kominki-na-biopaliwo',
    laminam: 'https://www.laminam.pl/',
    mcz: "https://www.wentor.pl/mcz/",
    unical: "http://www.unical.pl/produkty/urzadzenia_dla_domu/kotly_i_kominki_na_paliwa_stale",
    "spieki kwarcowe": 'http://laminam.pl/',
    sibu: 'https://www.sibu.at/en/',
    brunner: 'https://www.brunner.eu/pl/',
    bellfires: 'https://barbasbellfires.com/uk/fireplaces/',
    thorma: 'https://www.wentor.pl/thorma/',
    cadel: 'https://www.wentor.pl/cadel/',
    kobok: 'https://www.wentor.pl/kobok/'
}